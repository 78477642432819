<template>
    <IHeader color="light" size="lg" class="_text-align:center" style="height: 100vh">
        <h1>Renlisa</h1>
        <p>Krovinių pervežimas</p>
        <p>Vilniuje ir visoje Lietuvoje</p>

        <h3>Skambink arba rašyk</h3>
        <h1><a href="tel:+37063232886">+370 63232 886</a></h1>
    </IHeader>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

